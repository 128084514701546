<template>
    <!-- ***** Header Area Start ***** -->
    <div class="centered-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo fixed-menu"
        mode="horizontal"
        style="padding-left: 30px"
        @select="handleSelect"
      >
        <div class="menu-logo">
          <a class="logo">
              <img src="../assets/images/logo.png" alt="" class="logo_image" @click="return_home">
          </a>
        </div>
        <div class="menu-item">
          <el-sub-menu index="1">
            <template #title>智能化传感器</template>
            <el-menu-item index="1-1">液位传感器</el-menu-item>
            <el-menu-item index="1-2">温度传感器</el-menu-item>
          </el-sub-menu>
         
          <el-menu-item index="3" >生产及管理系统</el-menu-item>
          <el-menu-item index="4">数据采集与发布</el-menu-item>
          <el-menu-item index="5">设备电气控制改造</el-menu-item>
        </div>
      </el-menu>
    </div>
   
    <!-- ***** Header Area End ***** -->
  
    <!-- ***** Main Banner Area Start ***** -->
    <div class="Banner_sec" id="home">
            <!--  \\ Begin banner Side -->
            <div class="bannerside">
	            <div class="Center">
                    <!--  \\ Begin Left Side -->
                    <div class="leftside">
                        <h3>Responsive<span>Mobile Friendly</span></h3>
                        <p>November is a professional website template that is responsive and mobile friendly for any device. This template is provided by templatemo.com</p>
                        <a href="#about">MORE DETAILS</a>
                    </div>                        								
                    <!--  // End Left Side // -->
                    <!--  \\ Begin Right Side -->
                    <div class="rightside">
                      <div class="carousel">
                        <transition name="slide-fade">
                          <div class="slide" :key="currentIndex" :style="{ backgroundImage: 'url(' + currentItem.imageUrl + ')' }">
                            <div class="content">
                              <h3>{{ currentItem.title }}</h3>
                              <p>{{ currentItem.description }}</p>
                            </div>
                          </div>
                        </transition>
                        <button class="prev" @click="prevSlide">&#10094;</button>
                        <button class="next" @click="nextSlide">&#10095;</button>
                      </div>
                    </div>
                    <!--  // End Right Side // -->
	            </div>
            </div>
            <!--  // End banner Side // -->
            <div class="clear"></div>
        </div>
  <div class="item-details-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <div class="line-dec"></div>
            <h2>在此查看<em>项目详情</em>。</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
    <div class="center">
      <h2>中央内容</h2>
      <!-- 这里放置中央内容 -->
    </div>
    <div class="options">
      <button @click="selectedOption = 1">选项1</button>
      <button @click="selectedOption = 2">选项2</button>
      <button @click="selectedOption = 3">选项3</button>
    </div>
    <div class="content-function">
      <div class="image">
        <!-- 这里放置图片 -->
        <img src="../assets/images/banner-bg.jpg" alt="" style="border-radius: 20px;">
      </div>
      <div class="text">
        <h3>{{ options[selectedOption - 1].title }}</h3>
        <br/>
        <p>{{ options[selectedOption - 1].description }}</p>
      </div>
    </div>
  </div>
  </div>
      <!-- LAst section wroking -->
      <!-- <div class="intellifent_working">
        
      </div> -->
 
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p>Copyright © 2024 LangFang D&G Machinery Technology Co., Ltd.</p>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  // Import AOS styles (you can also include this in your styles if you prefer)
  import 'aos/dist/aos.css';
  // import '../assets/js/jquery.sudoSlider.min.js'
  // import '../assets/js/global.js'

  // Import AOS
  import AOS from 'aos';
  // Import Swiper styles
  // import 'swiper/swiper-bundle.css';

  // // Import Swiper
  // import Swiper, { Navigation } from 'swiper';
  // Swiper.use([Navigation]);

  export default {
    name: 'electronic',
    data() {
        return {
          currentIndex: 0,
          items: [
            { 
              imageUrl: 'https://via.placeholder.com/600x300?text=Image+1',
              title: 'Title 1',
              description: 'Description 1'
            },
            { 
              imageUrl: 'https://via.placeholder.com/600x300?text=Image+2',
              title: 'Title 2',
              description: 'Description 2'
            },
            { 
              imageUrl: 'https://via.placeholder.com/600x300?text=Image+3',
              title: 'Title 3',
              description: 'Description 3'
            }
            // 添加更多图片或内容
          ],
          intervalId: null,
          intervalDuration: 5000, // 自动播放间隔时间，单位毫秒
          activeIndex: '1',
          activeIndex2: '1',
          selectedOption: 1,
          isMobile: false,
          isOpen: false,
          options: [
            { title: "生产与管理系统的智能集成", description: "生产与管理系统的智能集成是指利用智能技术将生产和管理过程整合在一起的系统。这种系统可以利用人工智能、大数据分析、机器学习等技术，实现生产过程的自动化、优化和智能化管理。" },
            { title: "选项2标题", description: "选项2描述" },
            { title: "选项3标题", description: "选项3描述" }
          ]
        }
      },
      computed: {
        currentItem() {
          return this.items[this.currentIndex];
        }
      },
      methods: {
        
        handleSelect(key, keyPath) {
          // console.log(key, keyPath);
          if (key === '1-1' ) {
          // Navigate to the target route using $router.push
          this.$router.push('/frist');
          // router.push({ path: '/intelligent', replace: true });
          }else if(key === '3'){
            this.$router.push('/Manage');
          }
          else if(key === '4'){
            this.$router.push('/Data');
          }
        },
        initAOS() {
          AOS.init({
            duration: 1000, // Set the global duration for all animations
          });
        },
        prevSlide() {
          this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
        },
        nextSlide() {
          this.currentIndex = (this.currentIndex + 1) % this.items.length;
        },
        startAutoPlay() {
          this.intervalId = setInterval(() => {
            this.nextSlide();
          }, this.intervalDuration);
        },
        stopAutoPlay() {
          clearInterval(this.intervalId);
        },
        return_home(){
         this.$router.push('/');
      },
      checkIsMobile() {
      this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
    },
      },
      mounted() {
        this.initAOS();
        this.startAutoPlay();
        this.checkIsMobile();
      window.addEventListener('resize', this.checkIsMobile);
      },
      beforeDestroy() {
        this.stopAutoPlay();
      }
    }
    window.addEventListener('scroll', function() {
    var fixedMenu = document.querySelector('.fixed-menu');
  
    // Adjust the scroll threshold based on your needs
    var scrollThreshold = 30;
  
    if (window.scrollY > scrollThreshold) {
      fixedMenu.classList.add('scrolling');
    } else {
      fixedMenu.classList.remove('scrolling');
    }
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
      /* Import external CSS files */
      @import '../assets/css/style.css';
      @import '../assets/css/fontawesome.css';
      @import '../assets/css/templatemo-liberty-market.css';
      @import '../assets/css/media.css';
      @import '../assets/css/theme.css';
      /* @import '../assets/css/font-awesome.min.css'; */
      /* @import '../assets/css/owl.css';
      @import '../assets/css/animate.css'; */
      /* @import '../vendor/bootstrap/css/bootstrap.min.css'; */
      .el-carousel{
        position: relative;
        height: 350px !important;
      }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  /* a {
    color: #42b983;
  } */
  .fixed-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  max-width: 100%;
  min-height: 70px;
  border-radius: 0px;
  width: 100%;
  top: 0;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  /* padding: 5px 10px 15px 10px; */
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  /* background: none; //覆盖背景 
  box-shadow: none; //覆盖阴影 
  backdrop-filter: none; */
  border-bottom: solid 0px var(--el-menu-border-color) !important;
  color: white;
  
}
.scrolling {
  top:30px;
   /* Updated top position when scrolling */
  max-width: 1200px;
  border-radius: 50px;
  justify-content: space-between;
  background-color: var(--el-menu-bg-color);
  color:black;
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
}
.centered-menu {
  display: flex;
  justify-content: center;
  
}
.menu-logo .logo img {
  width:auto;
  height:50px;
  /* padding-top: 10px; */
}
.el-popper {
    /* Add your styles for the el-popper class here */
    background-color: none !important; /* Example background color */
    border-radius: 5px; /* Example border radius */
    padding: 10px; /* Example padding */
  }

  .drawer-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

  .swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  /* .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  } */

  /* .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  } */
  .sensor-container .sensor-text p{
  justify-content: center;
  display: flex;
}
.sensor-container .sensor-text h2{
  justify-content: center;
  display: flex;
}
.sensor-diagram{
    display: flex;
    justify-content: center;
}
.sensor-diagram img{
  width:auto;
}
.el-menu--horizontal .el-menu .el-menu-item{
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: gray;
  border-radius: 50px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .carousel {
    /* display: flex; */
    display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.slide {
  /* display: none; */
  width: 100%;
  height: 300px; /* Adjust height as needed */
  background-size: cover;
  position: relative;
}
.content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
}
.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
.slide-fade-enter-active, .slide-fade-leave-active {
  transition-duration: 500ms;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  margin-left: 100%;
  opacity: 0;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  margin-bottom: 20px;
}

.options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.options button {
  margin: 0 10px;
}

.content-function {
  display: flex;
}

.image {
  width: 50%; /* 左边占一半宽度 */
  margin-right: 20px;
}

.text {
  width: 50%; /* 右边占一半宽度 */
}

.text h3 {
  margin-top: 0;
}
  </style>