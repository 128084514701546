<template>
  <!-- ***** Header Area Start ***** -->
  <div class="centered-menu">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo fixed-menu"
      mode="horizontal"
      style="padding-left: 30px;"
      @select="handleSelect"
    >
        <div class="menu-logo">
          <a class="logo">
              <img src="../assets/images/logo.png" alt="" class="logo_image" @click="return_home">
          </a>
        </div>
        <span style="font-size:30px;cursor:pointer;color:white; padding-right: 10px;" @click="handleDrawer" class="drawer_menu">&#9776;</span>
      <div class="menu-item">
        <!-- @click="$router.push('/Intelligent');" -->
        <el-sub-menu index="1">
          <template #title>智能化传感器</template>
          <el-menu-item index="1-1">液位传感器</el-menu-item>
          <el-menu-item index="1-2">温度传感器</el-menu-item>
        </el-sub-menu>
        
        <el-menu-item index="3" >生产及管理系统</el-menu-item>
        <el-menu-item index="4">数据采集与发布</el-menu-item>
        <el-menu-item index="5">设备电气控制改造</el-menu-item>
      </div>
      
    </el-menu>
    
  </div>
 
  <!-- ***** Header Area End ***** -->

  <!-- ***** Main Banner Area Start ***** -->
  <div class="main-banner">
    <div class="container" style="margin-left: 90px;">
      <div class="row">
        <div class="col-lg-6 align-self-center">
          <div class="header-text" style="padding: 20px 15px 15px 15px;">
            <h6>Liberty NFT Market</h6>
            <h3 style="font-weight: 700;">Create, Sell &amp; Collect Top NFT's.</h3>
            <p>Liberty NFT Market is a really cool and professional design for your NFT websites. This HTML CSS template is based on Bootstrap v5 and it is designed for NFT related web portals. Liberty can be freely downloaded from TemplateMo's free css templates.</p>
            <div class="buttons">
              <div class="border-button">
                <a href="#">Explore Top NFTs</a>
              </div>
              <div class="main-button">
                <a href="https://youtube.com/templatemo" target="_blank">Watch Our Videos</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ***** Main Banner Area End ***** -->
  
  <div class="categories-collections">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="categories">
            <div class="row" style="justify-content: center;">
              <div class="col-lg-12">
                <div class="section-heading">
                  <div class="line-dec"></div>
                  <h2>不同种类的<em>智能化产品</em></h2>
                </div>
              </div>
              <div class="col-lg-2 col-sm-6">
                <div class="item">
                  <div class="icon">
                    <img src="../assets/images/product_icons/icon-01.png" alt="">
                  </div>
                  <h4>智能化传<br/>感器</h4>
                  <div class="icon-button">
                    <a href="/frist"><i class="fa fa-angle-right"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-sm-6">
                <div class="item">
                  <div class="icon">
                    <img src="../assets/images/product_icons/icon-02.png" alt="">
                  </div>
                  <h4>物联网系统</h4>
                  <div class="icon-button">
                    <a href="/Manage"><i class="fa fa-angle-right"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-sm-6">
                <div class="item">
                  <div class="icon">
                    <img src="../assets/images/product_icons/icon-03.png" alt="">
                  </div>
                  <h4>生产及管理<br/>系统</h4>
                  <div class="icon-button">
                    <a href="/Manage"><i class="fa fa-angle-right"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-sm-6">
                <div class="item">
                  <div class="icon">
                    <img src="../assets/images/product_icons/icon-04.png" alt="">
                  </div>
                  <h4>数据采集<br/>与发布</h4>
                  <div class="icon-button">
                    <a href="/Data"><i class="fa fa-angle-right"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-sm-6">
                <div class="item">
                  <div class="icon">
                    <img src="../assets/images/product_icons/icon-05.png" alt="">
                  </div>
                  <h4>设备电气控制改造</h4>
                  <div class="icon-button">
                    <a href="#"><i class="fa fa-angle-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <div class="create-nft">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading" >
            <div class="line-dec"></div>
            <h2>智能化产品主要特点</h2>
          </div>
        </div>
        <!-- <div class="col-lg-4">
          <div class="main-button">
            <a href="#">Create Your NFT Now</a>
          </div>
        </div> -->
        
        <div class="col-lg-4">
          <div class="item first-item">
            <div class="icon">
              <img src="../assets/images/product_icons/icon-02.png" alt="">
            </div>
            <h4>生产与管理系统的智能集成</h4>
            <p>生产与管理系统的智能集成是指利用智能技术将生产和管理过程整合在一起的系统。这种系统可以利用人工智能、大数据分析、机器学习等技术，实现生产过程的自动化、优化和智能化管理。</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="item second-item">
            <div class="icon">
              <img src="../assets/images/product_icons/icon-04.png" alt="">
            </div>
            <h4>设备电气控制改造提升效率</h4>
            <p>设备电气控制改造是指对现有设备的电气控制系统进行更新和改进，以提高设备的 <a href="#" target="_blank" rel="nofollow">效率和性能</a>。 这种改造可以通过多种方式实现，包括更新控制器、传感器和执行器，优化控制算法，提升能源利用效率等。</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="item">
            <div class="icon">
              <img src="../assets/images/product_icons/icon-06.png" alt="">
            </div>
            <h4>实时数据采集与发布</h4>
            <p>实时数据采集与发布利用传感器 <a rel="nofollow" href="#" target="_parent">收集数据</a> ，通过网络传输至应用程序，提供实时见解，以优化业务流程和决策效率。</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="currently-market">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-heading">
            <div class="line-dec"></div>
            <h2><em>Items</em> Currently In The Market.</h2>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="filters">
            <ul>
              <li data-filter="*"  class="active">All Items</li>
              <li data-filter=".msc">Music Art</li>
              <li data-filter=".dig">Digital Art</li>
              <li data-filter=".blc">Blockchain</li>
              <li data-filter=".vtr">Virtual</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row grid">
            <div class="col-lg-6 currently-market-item all msc">
              <div class="item">
                <div class="left-image">
                  <img src="../assets/images/market-01.jpg" alt="" style="border-radius: 20px; min-width: 195px;">
                </div>
                <div class="right-content">
                  <h4>Music Art Super Item</h4>
                  <span class="author">
                    <img src="../assets/images/author.jpg" alt="" style="max-width: 50px; border-radius: 50%;">
                    <h6>Liberty Artist<br><a href="#">@libertyart</a></h6>
                  </span>
                  <div class="line-dec"></div>
                  <span class="bid">
                    Current Bid<br><strong>2.03 ETH</strong><br><em>($8,240.50)</em>
                  </span>
                  <span class="ends">
                    Ends In<br><strong>4D 08H 15M 42S</strong><br><em>(July 24th, 2022)</em>
                  </span>
                  <div class="text-button">
                    <a href="#">View Item Details</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 currently-market-item all dig">
              <div class="item">
                <div class="left-image">
                  <img src="../assets/images/market-01.jpg" alt="" style="border-radius: 20px; min-width: 195px;">
                </div>
                <div class="right-content">
                  <h4>Digital Crypto Artwork</h4>
                  <span class="author">
                    <img src="../assets/images/author.jpg" alt="" style="max-width: 50px; border-radius: 50%;">
                    <h6>Liberty Artist<br><a href="#">@libertyart</a></h6>
                  </span>
                  <div class="line-dec"></div>
                  <span class="bid">
                    Current Bid<br><strong>2.03 ETH</strong><br><em>($7,200.50)</em>
                  </span>
                  <span class="ends">
                    Ends In<br><strong>2D 06H 30M 25S</strong><br><em>(July 26th, 2022)</em>
                  </span>
                  <div class="text-button">
                    <a href="#">View Item Details</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 currently-market-item all blc">
              <div class="item">
                <div class="left-image">
                  <img src="../assets/images/market-01.jpg" alt="" style="border-radius: 20px; min-width: 195px;">
                </div>
                <div class="right-content">
                  <h4>Blockchain Item One</h4>
                  <span class="author">
                    <img src="../assets/images/author.jpg" alt="" style="max-width: 50px; border-radius: 50%;">
                    <h6>Liberty Artist<br><a href="#">@libertyart</a></h6>
                  </span>
                  <div class="line-dec"></div>
                  <span class="bid">
                    Current Bid<br><strong>3.64 ETH</strong><br><em>($6,600.00)</em>
                  </span>
                  <span class="ends">
                    Ends In<br><strong>6D 05H 40M 50S</strong><br><em>(July 28th, 2022)</em>
                  </span>
                  <div class="text-button">
                    <a href="#">View Item Details</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 currently-market-item all vtr">
              <div class="item">
                <div class="left-image">
                  <img src="../assets/images/market-01.jpg" alt="" style="border-radius: 20px; min-width: 195px;">
                </div>
                <div class="right-content">
                  <h4>Virtual Currency Art</h4>
                  <span class="author">
                    <img src="../assets/images/author.jpg" alt="" style="max-width: 50px; border-radius: 50%;">
                    <h6>Liberty Artist<br><a href="#">@libertyart</a></h6>
                  </span>
                  <div class="line-dec"></div>
                  <span class="bid">
                    Current Bid<br><strong>2.44 ETH</strong><br><em>($8,800.50)</em>
                  </span>
                  <span class="ends">
                    Ends In<br><strong>3D 05H 20M 58S</strong><br><em>(July 14th, 2022)</em>
                  </span>
                  <div class="text-button">
                    <a href="#">View Item Details</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 currently-market-item all vrt dig">
              <div class="item">
                <div class="left-image">
                  <img src="../assets/images/market-01.jpg" alt="" style="border-radius: 20px; min-width: 195px;">
                </div>
                <div class="right-content">
                  <h4>Digital Art Item</h4>
                  <span class="author">
                    <img src="../assets/images/author.jpg" alt="" style="max-width: 50px; border-radius: 50%;">
                    <h6>Liberty Artist<br><a href="#">@libertyart</a></h6>
                  </span>
                  <div class="line-dec"></div>
                  <span class="bid">
                    Current Bid<br><strong>2.50 ETH</strong><br><em>($8,400.50)</em>
                  </span>
                  <span class="ends">
                    Ends In<br><strong>4D 08H 32M 18S</strong><br><em>(July 16th, 2022)</em>
                  </span>
                  <div class="text-button">
                    <a href="#">View Item Details</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 currently-market-item all msc blc">
              <div class="item">
                <div class="left-image">
                  <img src="../assets/images/market-01.jpg" alt="" style="border-radius: 20px; min-width: 195px;">
                </div>
                <div class="right-content">
                  <h4>Blockchain Music Design</h4>
                  <span class="author">
                    <img src="../assets/images/author.jpg" alt="" style="max-width: 50px; border-radius: 50%;">
                    <h6>Liberty Artist<br><a href="#">@libertyart</a></h6>
                  </span>
                  <div class="line-dec"></div>
                  <span class="bid">
                    Current Bid<br><strong>2.44 ETH</strong><br><em>($8,200.50)</em>
                  </span>
                  <span class="ends">
                    Ends In<br><strong>5D 10H 22M 24S</strong><br><em>(July 18th, 2022)</em>
                  </span>
                  <div class="text-button">
                    <a href="#">View Item Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  
  <div class="item-details-page">
    <div class="container">
      <div class="row" style="justify-content: center;">
        <div class="col-lg-12">
          <div class="section-heading">
            <div class="line-dec"></div>
            <h2>立即联系我们，了解如何将智能化传感器<br/>集成到您的业务中，提升生产效率</h2>
          </div>
        </div>
        <div class="col-lg-8" >
          <form id="contact" action="" method="post">
              <div class="row">
                  <div class="col-lg-12">
                      <fieldset>
                          <label for="name">名字</label>
                          <input type="text" name="name" id="name" placeholder="Kingsley Hing" autocomplete="on" required>
                      </fieldset>
                  </div>
                  <div class="col-lg-12">
                      <fieldset>
                          <label for="email">邮件</label>
                          <input type="email" name="email" id="email" placeholder="Ex. hing@gmail.com" autocomplete="on" required>
                      </fieldset>
                  </div>
                  <div class="col-lg-12">
                      <fieldset>
                          <label for="tel">电话号码</label>
                          <input type="tel" name="tel" id="tel" placeholder="+86 16517525571" autocomplete="on" required>
                      </fieldset>
                  </div>
                  <div class="col-lg-12">
                      <fieldset>
                          <label for="title">项目标题</label>
                          <input type="text" name="title" id="title" placeholder="Lyon King" autocomplete="on" required>
                      </fieldset>
                  </div>
                  <div class="col-lg-12">
                      <fieldset>
                          <label for="attachment">附件</label>
                          <input type="file" id="attachment" name="myfiles[]" multiple  style="padding-top: 8px;"/>
                      </fieldset>
                  </div>
                  <div class="col-lg-12">
                      <fieldset>
                          <label for="content">信息</label>
                          <textarea name="content" id="content" placeholder="Enter your content here" required></textarea>
                      </fieldset>
                  </div>
                  
                  <div class="col-lg-12">
                      <fieldset>
                          <button type="submit" id="form-submit" class="orange-button">提交</button>
                      </fieldset>
                  </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p>Copyright © 2024 LangFang D&G Machinery Technology Co., Ltd.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'main_page',
  data() {
      return {
        isMobile: false,
        activeIndex: '1',
        activeIndex2: '1',
        isOpen: false,
      }
    },
    mounted() {
      
      this.checkIsMobile();
      window.addEventListener('resize', this.checkIsMobile);
      
    },
    methods: {
      handleSelect(key, keyPath) {
        if (key === '1-1' ) {
        // Navigate to the target route using $router.push
        this.$router.push('/frist');
        // router.push({ path: '/intelligent', replace: true });
        }else if(key === '3'){
          this.$router.push('/Manage');
        }
        else if(key === '4'){
          this.$router.push('/Data');
        }else if(key === '5'){
          this.$router.push('/Electronic');
        }
      },
        checkIsMobile() {
      this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
    },
    redirectToMainPage() {
      // Navigate to the main page
      this.$router.push('/main');
    },
    handleDrawer() {
      const drawer= document.querySelector('.drawer');
      const popupmenu = document.querySelector('.el-popper'); // 使用querySelector选择.fixed-menu类的元素
      
      if (drawer) {
        popupmenu.classList.remove('drawer');
      }else{
        popupmenu.classList.add('drawer'); // 如果找到了.fixed-menu元素，添加drawer样式
      }
      },
      return_home(){
         this.$router.push('/');
      }
    },
  }
  window.addEventListener('scroll', function() {
  var fixedMenu = document.querySelector('.fixed-menu');

  // Adjust the scroll threshold based on your needs
  var scrollThreshold = 30;

  if (window.scrollY > scrollThreshold) {
    fixedMenu.classList.add('scrolling');
  } else {
    fixedMenu.classList.remove('scrolling');
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Import external CSS files */
    @import '../assets/css/style.css';
    @import '../assets/css/fontawesome.css';
    @import '../assets/css/templatemo-liberty-market.css';
    /* @import '../assets/css/owl.css';
    @import '../assets/css/animate.css'; */
    /* @import '../vendor/bootstrap/css/bootstrap.min.css'; */
    
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
/* a {
  color: #42b983;
} */
.fixed-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  max-width: 100%;
  min-height: 70px;
  border-radius: 0px;
  width: 100%;
  top: 0;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  /* padding: 5px 10px 15px 10px; */
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  /* background: none; //覆盖背景 
  box-shadow: none; //覆盖阴影 
  backdrop-filter: none; */
  border-bottom: solid 0px var(--el-menu-border-color) !important;
  color: white;
  
}
.scrolling {
  top:30px;
   /* Updated top position when scrolling */
  max-width: 1200px;
  border-radius: 50px;
  justify-content: space-between;
  background-color: var(--el-menu-bg-color);
  color:black;
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
}
.centered-menu {
  display: flex;
  justify-content: center;
  
}
.menu-logo .logo img {
  width:auto;
  height:50px;
  /* padding-top: 10px; */
}
.el-popper {
    /* Add your styles for the el-popper class here */
    background-color: none !important; /* Example background color */
    border-radius: 5px; /* Example border radius */
    padding: 10px; /* Example padding */
  }
 
.el-menu--horizontal .el-menu .el-menu-item{
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}


.drawer-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

</style>
