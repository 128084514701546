<template>
    <div class="centered-menu">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo fixed-menu"
      mode="horizontal"
      style="padding-left: 30px"
      @select="handleSelect"
    >
      <div class="menu-logo">
        <a href="./main" class="logo">
            <img src="../assets/images/logo.png" alt="">
        </a>
      </div>
      <div class="menu-item">
        <el-menu-item index="1">处理中心</el-menu-item>
        <el-sub-menu index="2">
          <template #title>我的工作台</template>
          <el-menu-item index="2-1">选项1</el-menu-item>
          <el-menu-item index="2-2">选项2</el-menu-item>
          <el-menu-item index="2-3">选项3</el-menu-item>
          <el-sub-menu index="2-4">
            <template #title>选项4</template>
            <el-menu-item index="2-4-1">选项1</el-menu-item>
            <el-menu-item index="2-4-2">选项2</el-menu-item>
            <el-menu-item index="2-4-3">选项3</el-menu-item>
          </el-sub-menu>
        </el-sub-menu>
        <el-menu-item index="3" disabled>消息中心</el-menu-item>
        <el-menu-item index="4">订单管理</el-menu-item>
      </div>
    </el-menu>
  </div>
  <el-popover
    placement="top-start"
    title="标题"
    :width="200"
    trigger="hover"
    content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
  >
    <template #reference>
      <el-button>hover 激活</el-button>
    </template>
  </el-popover>

  <el-popover
    placement="bottom"
    title="标题"
    :width="200"
    trigger="click"
    content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
  >
    <template #reference>
      <el-button>click 激活</el-button>
    </template>
  </el-popover>

  <el-popover
    ref="popover"
    placement="right"
    title="标题"
    :width="200"
    trigger="focus"
    content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
  >
    <template #reference>
      <el-button>focus 激活</el-button>
    </template>
  </el-popover>

  <el-popover
    placement="bottom"
    title="标题"
    :width="200"
    trigger="manual"
    content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
    v-model:visible="visible"
  >
    <template #reference>
      <el-button @click="visible = !visible">手动激活</el-button>
    </template>
  </el-popover>
</template>

<script>
export default {
  name: 'Intelligent',
  data() {
      return {
        activeIndex: '1',
        activeIndex2: '1',
        visible: false,
      }
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath)
      },
    },
  }
  window.addEventListener('scroll', function() {
  var fixedMenu = document.querySelector('.fixed-menu');

  // Adjust the scroll threshold based on your needs
  var scrollThreshold = 30;

  if (window.scrollY > scrollThreshold) {
    fixedMenu.classList.add('scrolling');
  } else {
    fixedMenu.classList.remove('scrolling');
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Import external CSS files */
    @import '../assets/css/style.css';
    @import '../assets/css/fontawesome.css';
    @import '../assets/css/templatemo-liberty-market.css';
    /* @import '../assets/css/owl.css';
    @import '../assets/css/animate.css'; */
    /* @import '../vendor/bootstrap/css/bootstrap.min.css'; */
    
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
/* a {
  color: #42b983;
} */
.fixed-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  max-width: 100%;
  /* min-height: 80px; */
  border-radius: 0px;
  width: 100%;
  top: 0;
  justify-content: space-between;
  padding: 0px 10px 10px 10px;
  background: rgba( 255, 255, 255, 0.1 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  border-bottom: solid 0px var(--el-menu-border-color) !important;
  color: white;
}
.scrolling {
  top:30px;
   /* Updated top position when scrolling */
  max-width: 1200px;
  border-radius: 50px;
  justify-content: space-between;
  background-color: var(--el-menu-bg-color);
  color:black;
  background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 15px );
}
.centered-menu {
  display: flex;
  justify-content: center;
  
}
.menu-logo .logo img {
  width:auto;
  height:50px;
  padding-top: 10px;
}
.el-menu--horizontal .el-menu .el-menu-item{
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: gray;
  border-radius: 50px;
}
</style>