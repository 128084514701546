import { createApp } from 'vue'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '@fortawesome/fontawesome-free/css/all.css'
import router from './router.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import ElementPlus from 'element-plus';
import "element-plus/dist/index.css";
import App from './App.vue'


const app = createApp(App);
app.use(ElementPlus);
app.use(router);
app.mount('#app');
