<template>
    <!-- ***** Header Area Start ***** -->
    <div class="centered-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo fixed-menu"
        mode="horizontal"
        style="padding-left: 30px"
        @select="handleSelect"
      >
        <div class="menu-logo">
          <a href="/" @click="$router.push('/');" class="logo">
              <img src="../assets/images/logo.png" alt="" class="logo_image">
          </a>
        </div>
        <div class="menu-item">
          <el-sub-menu index="1">
            <template #title>智能化传感器</template>
            <el-menu-item index="1-1">液位传感器</el-menu-item>
            <el-menu-item index="1-2">温度传感器</el-menu-item>
          </el-sub-menu>
         
          <el-menu-item index="3" >生产及管理系统</el-menu-item>
          <el-menu-item index="4">数据采集与发布</el-menu-item>
          <el-menu-item index="5">设备电气控制改造</el-menu-item>
        </div>
      </el-menu>
    </div>
   
    <!-- ***** Header Area End ***** -->
  
    <!-- ***** Main Banner Area Start ***** -->
    <div class="page-heading normal-space">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h6>产品详细信息</h6>
          <h2>查看产品详情 </h2>
          <span>Z主页 > <a href="#">产品信息</a></span>
        </div>
      </div>
    </div>
  </div>
  <div class="item-details-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <div class="line-dec"></div>
            <h2>在此查看<em>项目详情</em>。</h2>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="left-image">
            <img src="../assets/images/banner-bg.jpg" alt="" style="border-radius: 20px;" @click="return_home">
          </div>
        </div>
        <div class="col-lg-5 align-self-center">
          <h4>浮球开关</h4>
          <span class="author">
            <h6>Liberty Artist<br><a href="#">@libertyart</a></h6>
          </span>
          <p>浮子开关是液位传感中最具成本效益但也是经过充分验证的技术之一。 浮子开关包括浮子内的磁铁和包含在安全壳体内的磁簧开关。 
            浮子随着液体的变化而移动，并且将使簧片开关打开或关闭，这取决于它是在空气还是液体中。 
            虽然设计简单，但该技术以极具吸引力的价格提供长期可靠性。根据用户选择的安装方式，取决于开关所在的罐或容器的设计和结构。
             通常，供应商将提供一系列安装选项，最常见的是水平/侧面安装和垂直安装。</p>
          <div class="row">
            <div class="col-5">
              <span class="bid">
                优点<br><strong>无动力，直接指示，相对便宜，各种输出</strong><br>
              </span>
            </div>
            <div class="line-dec"></div>
            <div class="col-5">
              <span class="owner">
                缺点<br><strong >在浮子接触之前必须存在侵入性，移动部件，大尺寸，大量液体。</strong ><br>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <!-- LAst section wroking -->
      <!-- <div class="intellifent_working">
        
      </div> -->
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p>Copyright © 2024 LangFang D&G Machinery Technology Co., Ltd.</p>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  // Import AOS styles (you can also include this in your styles if you prefer)
  import 'aos/dist/aos.css';

  // Import AOS
  import AOS from 'aos';
  // Import Swiper styles
  // import 'swiper/swiper-bundle.css';

  // // Import Swiper
  // import Swiper, { Navigation } from 'swiper';
  // Swiper.use([Navigation]);

  export default {
    name: 'frist_page',
    data() {
        return {
          activeIndex: '1',
          activeIndex2: '1',
          isMobile: false,
          isOpen: false,
        }
      },
      methods: {
        handleSelect(key, keyPath) {
          // console.log(key, keyPath);
          if (key === '1-1' ) {
          // Navigate to the target route using $router.push
          this.$router.push('/frist');
          // router.push({ path: '/intelligent', replace: true });
          }else if(key === '3'){
            this.$router.push('/Manage');
          }
          else if(key === '4'){
            this.$router.push('/Data');
          }else if(key === '5'){
          this.$router.push('/Electronic');
        }
        },
        initAOS() {
          AOS.init({
            duration: 1000, // Set the global duration for all animations
          });
        },
        return_home(){
         this.$router.push('/');
      },
      checkIsMobile() {
      this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
    },
      },
      mounted() {
        this.initAOS();
        this.checkIsMobile();
      window.addEventListener('resize', this.checkIsMobile);
      },

    }
    window.addEventListener('scroll', function() {
    var fixedMenu = document.querySelector('.fixed-menu');
  
    // Adjust the scroll threshold based on your needs
    var scrollThreshold = 30;
  
    if (window.scrollY > scrollThreshold) {
      fixedMenu.classList.add('scrolling');
    } else {
      fixedMenu.classList.remove('scrolling');
    }
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
      /* Import external CSS files */
      @import '../assets/css/style.css';
      @import '../assets/css/fontawesome.css';
      @import '../assets/css/templatemo-liberty-market.css';
      /* @import '../assets/css/owl.css';
      @import '../assets/css/animate.css'; */
      /* @import '../vendor/bootstrap/css/bootstrap.min.css'; */
      
   
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  /* a {
    color: #42b983;
  } */
  .fixed-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  max-width: 100%;
  min-height: 70px;
  border-radius: 0px;
  width: 100%;
  top: 0;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  /* padding: 5px 10px 15px 10px; */
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  /* background: none; //覆盖背景 
  box-shadow: none; //覆盖阴影 
  backdrop-filter: none; */
  border-bottom: solid 0px var(--el-menu-border-color) !important;
  color: white;
  
}
.drawer-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.scrolling {
  top:30px;
   /* Updated top position when scrolling */
  max-width: 1200px;
  border-radius: 50px;
  justify-content: space-between;
  background-color: var(--el-menu-bg-color);
  color:black;
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
}
.centered-menu {
  display: flex;
  justify-content: center;
  
}
.menu-logo .logo img {
  width:auto;
  height:50px;
  /* padding-top: 10px; */
}
.el-popper {
    /* Add your styles for the el-popper class here */
    background-color: none !important; /* Example background color */
    border-radius: 5px; /* Example border radius */
    padding: 10px; /* Example padding */
  }
  .swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  /* .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  } */

  /* .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  } */
  .sensor-container .sensor-text p{
  justify-content: center;
  display: flex;
}
.sensor-container .sensor-text h2{
  justify-content: center;
  display: flex;
}
.sensor-diagram{
    display: flex;
    justify-content: center;
}
.sensor-diagram img{
  width:auto;
}
.el-menu--horizontal .el-menu .el-menu-item{
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: gray;
  border-radius: 50px;
}
  </style>