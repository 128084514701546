<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: white;
  /* margin-top: 60px; */
}

.el-carousel__item--card .rs232-name {
  visibility: visible;
    opacity: 1;
    display: block !important;
}
.el-carousel__container {
  position: relative;
  height: 550px;
  /* background-color: rgba(240, 240, 240, 0.5); */
  /* height: 60vh;  */
  border-radius: 10px;
  /* Add !important if needed */
}
.el-carousel__content {
  height: 100%; /* 将内容高度设置为100%以填充整个容器 */
  /* Add any other styles for the content */
  
}

.el-carousel__mask {
    background-color: transparent;
  }
  .el-menu{
    /* background-color:black; */
    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 15px );
    border-radius: 20px;
  }
  .el-popper-container-1844 .el-popper{
    border: 0.5px solid var(--el-border-color-light) !important;
    border-radius: 50px;
  } 
  .el-menu--horizontal .el-menu .el-sub-menu__title {
    background-color: transparent  !important;
  }
  .el-popper .is-pure .is-light{
    background-color: transparent;
  }
</style>
