import { createRouter, createWebHistory } from 'vue-router';
import Main from './components/Landing.vue';
import Intelligent from './components/Intelligent.vue';
import Frist from "./components/Frist.vue";
import Product from "./components/product.vue";
import Manage from "./components/manage.vue";
import Data from "./components/Data.vue";
import Menu from "./components/menu.vue";
// import AboutUs from './components/AboutUs.vue';
import Electronic from "./components/electronic.vue";

const routes = [
  { path: '/main', component: Main },
  // { path: '/aboutUs', component: AboutUs },
  { path: '/Electronic', component: Electronic  },
  { path: '/Intelligent', component: Intelligent  },
  { path: '/frist', component: Frist  },
  { path: '/Product', component: Product  },
  { path: '/Manage', component: Manage },
  { path: '/Data', component: Data },
  { path: '/Menu', component: Menu },
  { path: '/', redirect: '/main' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



export default router;
