<template>
    <!-- ***** Header Area Start ***** -->
    <div class="centered-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo fixed-menu"
        mode="horizontal"
        style="padding-left: 30px"
        @select="handleSelect"
      >
        <div class="menu-logo">
          <a href="./main" class="logo">
              <img src="../assets/images/logo.png" alt="" class="logo_image">
          </a>
        </div>
        <div class="menu-item">
          <el-sub-menu index="1">
            <template #title>智能化传感器</template>
            <el-menu-item index="1-1">液位传感器</el-menu-item>
            <el-menu-item index="1-2">温度传感器</el-menu-item>
          </el-sub-menu>
         
          <el-menu-item index="3" >生产及管理系统</el-menu-item>
          <el-menu-item index="4">数据采集与发布</el-menu-item>
          <el-menu-item index="5">设备电气控制改造</el-menu-item>
        </div>
      </el-menu>
    </div>
   
    <!-- ***** Header Area End ***** -->
  
    <!-- ***** Main Banner Area Start ***** -->
    <div class="main-banner">
      <div class="container">
        <div class="row">
        </div>
      </div>
    </div>
    <div class="create-nft">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading">
              <div class="line-dec"></div>
              <h2>液位传感器主要<em>特点</em></h2>
            </div>
          </div>
          <!-- <div class="col-lg-4">
            <div class="main-button">
              <a href="#">Create Your NFT Now</a>
            </div>
          </div> -->
          
          <div class="col-lg-4">
            <div class="item first-item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-02.png" alt="">
              </div>
              <h4>精度</h4>
              <p>精度是指液位传感器测量结果与实际液位值之间的接近程度。
                高精度的传感器能够提供准确的液位测量，这对于需要精确控制液位的应用非常关键，如工业过程控制和实验室应用。</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item second-item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-04.png" alt="">
              </div>
              <h4>可靠性</h4>
              <p>可靠性涉及到传感器在长时间内保持稳定和一致的性能。可靠的液位传感器能够在各种环境条件下正常运作，并且不容易受到干扰或损坏。
                在工业和其他关键应用中，可靠性是确保系统稳定运行的重要因素。</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-06.png" alt="">
              </div>
              <h4>测量范围</h4>
              <p>测量范围表示传感器能够测量液位的最大和最小范围。选择适当的测量范围对于确保传感器在特定应用中能够满足需求至关重要。
                过小的测量范围可能导致测量不准确，而过大的测量范围可能导致测量分辨率降低。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="intellifent_detail" style="padding: 60px 0px 150px 0px;">
        <!-- Section 1: Image on the left, Text on the right -->
        <div class="section-heading" >
            <div class="line-dec"></div>
            <h2>液位传感器的<em>类型</em></h2>
        </div>
        <div class="py-7 px-4" style="padding: 30px" data-aos="fade-up" data-aos-duration="2000">
          <!-- Added px-4 for horizontal padding -->
          <div class="container">
            <div class="row justify-content-center align-items-center gx-lg-0">
              <div
                class="col-md-6"
                style="display: flex; justify-content: flex-start"
              >
                <div
                  class="image-container"
                  style="max-height: 50vh; width: 100%"
                >
                  <img
                    class="images"
                    src="../assets/images/intelligent/product_1.jpg"
                    style="width: 100%; height: auto"
                  />
                </div>
              </div>

              <div class="col-md-6 p-4 p-mb-5" style="text-align: left" >
                <h2
                  class="mb-3"
                  style="font-weight: 600; font-size: 25px; line-height: 48px"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  浮球式液位传感器
                </h2>
                <p class="text-sm" id="block_explain">
                  <br />由行业产品磁性浮球、测量导管、信号单元、电子单元、接线盒及安装件组成。一般磁性浮球的比重小于0.5，可漂于液面之 上并沿测量导管上下移动。
                  导管内装有测量元件，它可以在外磁作用下将被测液位信号转换成正比于液位变化的电阻信号，并将电子单元转换成4～20ma或其它 电子商务标准信号输出。
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Section 2: Image on the right, Text on the left -->
        <div class="py-7 px-4" style="padding: 30px" data-aos="fade-up" data-aos-duration="2000">
          <!-- Added px-4 for horizontal padding -->
          <div class="container">
            <div class="row justify-content-center align-items-center gx-lg-0">
              <div
                class="col-md-6 order-md-2"
                style="display: flex; justify-content: flex-end"
              >
                <div
                  class="image-container"
                  style="max-height: 50vh; width: 100%"
                >
                  <img
                    class="images"
                    src="../assets/images/intelligent/product_2.jpg"
                    style="width: 100%; height: auto"
                  />
                </div>
              </div>
              <div class="col-md-6 p-4 p-mb-5" style="text-align: left">
                <h2
                  class="mb-3"
                  style="font-weight: 600; font-size: 25px; line-height: 48px"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  浮筒式液位传感器
                </h2>
                <p class="text-sm" id="block_explain">
                  <br />是将磁性浮球改为浮筒，浮筒式液位传感器是利用微小的金属膜应变传感技术来测量液体的液位、界位或密度的。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="py-7 px-4" style="padding: 30px" data-aos="fade-up" data-aos-duration="2000">
          <!-- Added px-4 for horizontal padding -->
          <div class="container">
            <div class="row justify-content-center align-items-center gx-lg-0">
              <div
                class="col-md-6"
                style="display: flex; justify-content: flex-start"
              >
                <div
                  class="image-container"
                  style="max-height: 50vh; width: 100%"
                >
                  <img
                    class="images"
                    src="../assets/images/intelligent/product_3.png"
                    style="width: 100%; height: auto"
                  />
                </div>
              </div>

              <div class="col-md-6 p-4 p-mb-5" style="text-align: left" >
                <h2
                  class="mb-3"
                  style="font-weight: 600; font-size: 25px; line-height: 48px"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                静压式液位传感器
                </h2>
                <p class="text-sm" id="block_explain">
                  <br />是利用防爆流量计液体静压力的测量原理工作。一般选用硅压力测压传感器将测量到的压力转换成电信号，
                  之后再经放大电路放大和补偿电路补偿，最后以4～20ma或0～10ma电流方式输出。
                </p>
              </div>
            </div>
          </div>
        </div>


        <div class="section-heading" style="margin-top: 20px;">
          <div class="line-dec"></div>
          <h1>液位传感器工作原理</h1>
        </div>
        <el-carousel :interval="5000" arrow="always" >
          <el-carousel-item >
            <div class="sensor-container" >
              <div class="sensor-diagram">
                <!-- Insert an image or interactive diagram here -->
                <img src="../assets/images/intelligent/Switch.jpg" alt="Sensor Diagram" >
              </div>
              <div class="sensor-text">
                <h2>光电液位开关</h2>
                <p>光电液位开关包含发光LED和光电晶体管。在空气中，红外光反射回晶体管；液体中，红外液体折射减少能量到晶体管。
                  紧凑、可靠，适用于高、低、中液位检测，防泄漏，保护设备。</p>
                <!-- Additional text explanation here -->
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item >
            <div class="sensor-container" >
              <div class="sensor-diagram">
                <!-- Insert an image or interactive diagram here -->
                <img src="../assets/images/intelligent/Switch.jpg" alt="Sensor Diagram" >
              </div>
              <div class="sensor-text">
                <h2>光电液位开关</h2>
                <p>光电液位开关包含发光LED和光电晶体管。在空气中，红外光反射回晶体管；液体中，红外液体折射减少能量到晶体管。
                  紧凑、可靠，适用于高、低、中液位检测，防泄漏，保护设备。</p>
                <!-- Additional text explanation here -->
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item >
            <div class="sensor-container" >
              <div class="sensor-diagram">
                <!-- Insert an image or interactive diagram here -->
                <img src="../assets/images/intelligent/Switch2.jpg" alt="Sensor Diagram" >
              </div>
              <div class="sensor-text">
                <h2>电容式液位传感器</h2>
                <p>电容式液位传感器适用于各种固体、液体和混合材料，可分为接触式和非接触式配置。某些传感器可安装在容器外。
                  选择时需考虑不同材料和槽设计，进行校准以匹配介电常数。
                  由于是接触技术，流体附着可能影响传感器可靠性。</p>
                <!-- Additional text explanation here -->
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item >
            <div class="sensor-container" >
              <div class="sensor-diagram">
                <!-- Insert an image or interactive diagram here -->
                <img src="../assets/images/intelligent/Switch3.jpg" alt="Sensor Diagram" >
              </div>
              <div class="sensor-text">
                <h2>微波/雷达传感器</h2>
                <p>雷达与超声波类似，但脉冲以光速传播，受流体介电常数影响，影响可靠性和可重复性。尽管初投资高，
                  雷达提供精确液位信息并补偿容器结构。
                  适用于泡沫和粘性物质，制造商致力于提高技术普及度。</p>
                <!-- Additional text explanation here -->
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item >
            <div class="sensor-container" >
              <div class="sensor-diagram">
                <!-- Insert an image or interactive diagram here -->
                <img src="../assets/images/intelligent/Switch4.jpg" alt="Sensor Diagram" >
              </div>
              <div class="sensor-text">
                <h2>振动或音叉传感器</h2>
                <p>振动传感器技术适用于固体和液位控制，包括粘性材料、泡沫、粉末和细粒固体。
                  调谐叉的应用限于溢出或运行干式应用，不提供连续过程测量，但可与连续液位检测系统结合，用于过度填充和泄漏的报警。</p>
                <!-- Additional text explanation here -->
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item >
            <div class="sensor-container" >
              <div class="sensor-diagram">
                <!-- Insert an image or interactive diagram here -->
                <img src="../assets/images/intelligent/Switch5.jpg" alt="Sensor Diagram" >
              </div>
              <div class="sensor-text">
                <h2>光电液位开关</h2>
                <p>光电液位开关包含发光LED和光电晶体管。在空气中，红外光反射回晶体管；液体中，红外液体折射减少能量到晶体管。
                  紧凑、可靠，适用于高、低、中液位检测，防泄漏，保护设备。</p>
                <!-- Additional text explanation here -->
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item >
            <div class="sensor-container" >
              <div class="sensor-diagram">
                <!-- Insert an image or interactive diagram here -->
                <img src="../assets/images/intelligent/Switch6.jpg" alt="Sensor Diagram" >
              </div>
              <div class="sensor-text">
                <h2>导电性或电阻</h2>
                <p>导电传感器用于检测导电液体，包括两个金属探针，一个传输低电压，另一个在液位时被切断，触及液体时激活开关。
                  安全易用，需定期维护以确保正常工作。</p>
                <!-- Additional text explanation here -->
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item >
            <div class="sensor-container" >
              <div class="sensor-diagram">
                <!-- Insert an image or interactive diagram here -->
                <img src="../assets/images/intelligent/Switch7.jpg" alt="Sensor Diagram" >
              </div>
              <div class="sensor-text" style="justify-content: center;display: flex;">
                <h2>浮球开关</h2>
                <p>浮子开关是成本效益高、经验证的液位传感技术。包括浮子、磁铁和磁簧开关。浮子随液位变化移动，触发簧片开关，简单设计、
                  吸引人的价格和可靠性。可选水平/侧面和垂直安装</p>
                <!-- Additional text explanation here -->
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- LAst section wroking -->
      <!-- <div class="intellifent_working">
        
      </div> -->
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p>Copyright © 2024 LangFang D&G Machinery Technology Co., Ltd.</p>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  // Import AOS styles (you can also include this in your styles if you prefer)
  import 'aos/dist/aos.css';

  // Import AOS
  import AOS from 'aos';
  // Import Swiper styles
  // import 'swiper/swiper-bundle.css';

  // // Import Swiper
  // import Swiper, { Navigation } from 'swiper';
  // Swiper.use([Navigation]);

  export default {
    name: 'frist_page',
    data() {
        return {
          activeIndex: '1',
          activeIndex2: '1',
        }
      },
      methods: {
        handleSelect(key, keyPath) {
          console.log(key, keyPath);
          if (key === '1-1' ) {
          // Navigate to the target route using $router.push
          this.$router.push('/frist');
          // router.push({ path: '/intelligent', replace: true });
          }else if(key === '3'){
            this.$router.push('/Manage');
          }
          else if(key === '4'){
            this.$router.push('/Data');
          }else if(key === '5'){
          this.$router.push('/Electronic');
        }
        },
        initAOS() {
          AOS.init({
            duration: 1000, // Set the global duration for all animations
          });
        },
      },
      mounted() {
        this.initAOS();
      },

    }
    window.addEventListener('scroll', function() {
    var fixedMenu = document.querySelector('.fixed-menu');
  
    // Adjust the scroll threshold based on your needs
    var scrollThreshold = 30;
  
    if (window.scrollY > scrollThreshold) {
      fixedMenu.classList.add('scrolling');
    } else {
      fixedMenu.classList.remove('scrolling');
    }
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
      /* Import external CSS files */
      @import '../assets/css/style.css';
      @import '../assets/css/fontawesome.css';
      @import '../assets/css/templatemo-liberty-market.css';
      /* @import '../assets/css/owl.css';
      @import '../assets/css/animate.css'; */
      /* @import '../vendor/bootstrap/css/bootstrap.min.css'; */
      
      .main-banner {
        height: 650px;
      }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  /* a {
    color: #42b983;
  } */
  .fixed-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  max-width: 100%;
  /* min-height: 80px; */
  border-radius: 0px;
  width: 100%;
  top: 0;
  justify-content: space-between;
  padding: 0px 10px 10px 10px;
  background: rgba( 255, 255, 255, 0.1 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  border-bottom: solid 0px var(--el-menu-border-color) !important;
  color: white;
}
.scrolling {
  top:30px;
   /* Updated top position when scrolling */
  max-width: 1200px;
  border-radius: 50px;
  justify-content: space-between;
  background-color: var(--el-menu-bg-color);
  color:black;
  background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 15px );
}
.centered-menu {
  display: flex;
  justify-content: center;
  
}
.menu-logo .logo img {
  width:auto;
  height:50px;
  padding-top: 10px;
}
  .swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  /* .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  } */

  /* .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  } */
  .sensor-container .sensor-text p{
  justify-content: center;
  display: flex;
}
.sensor-container .sensor-text h2{
  justify-content: center;
  display: flex;
}
.sensor-diagram{
    display: flex;
    justify-content: center;
}
.sensor-diagram img{
  width:auto;
}
.el-menu--horizontal .el-menu .el-menu-item{
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: gray;
  border-radius: 50px;
}
  </style>