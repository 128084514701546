<template>
    <!-- ***** Header Area Start ***** -->
    <div class="centered-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo fixed-menu"
        mode="horizontal"
        style="padding-left: 30px"
        @select="handleSelect"
      >
        <div class="menu-logo">
          <a href="/" @click="$router.push('/');" class="logo">
              <img src="../assets/images/logo.png" alt="" class="logo_image" @click="return_home">
          </a>
        </div>
        <div class="menu-item">
          <el-sub-menu index="1">
            <template #title>智能化传感器</template>
            <el-menu-item index="1-1">液位传感器</el-menu-item>
            <el-menu-item index="1-2">温度传感器</el-menu-item>
          </el-sub-menu>
         
          <el-menu-item index="3" >生产及管理系统</el-menu-item>
          <el-menu-item index="4">数据采集与发布</el-menu-item>
          <el-menu-item index="5">设备电气控制改造</el-menu-item>
        </div>
      </el-menu>
    </div>
   
    <!-- ***** Header Area End ***** -->
  
    <!-- ***** Main Banner Area Start ***** -->
    <div class="main-banner">
      <div class="container">
        <div class="row">
        </div>
      </div>
    </div>
  <div class="author-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <div class="line-dec"></div>
            <h2>生产及管理系统<em>种类</em>.</h2>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="item">
            <div class="row">
              <div class="col-lg-12">
                
                <img src="../assets/images/data/data1.jpeg" alt="" style="border-radius: 20px;">
                <h4>简道云MES系统</h4>
              </div>
              <div class="col-lg-12">
                <div class="line-dec"></div>
                <div class="row">
                  <div class="col-6">
                    <span>官网: <br> <strong>这里</strong></span>
                  </div>
                  <div class="col-6">
                    <span>重点 <br> <strong>特别</strong></span>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="main-button">
                  <a href="./Product">查看详情</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="item">
            <div class="row">
              <div class="col-lg-12">
                
                <img src="../assets/images/data/data2.jpeg" alt="" style="border-radius: 20px;">
                <h4>鼎捷软件</h4>
              </div>
              <div class="col-lg-12">
                <div class="line-dec"></div>
                <div class="row">
                  <div class="col-6">
                    <span>官网: <br> <strong>这里</strong></span>
                  </div>
                  <div class="col-6">
                    <span>重点 <br> <strong>特别</strong></span>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="main-button">
                  <a href="./Product">查看详情</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="item">
            <div class="row">
              <div class="col-lg-12">
                
                <img src="../assets/images/data/data3.jpeg" alt="" style="border-radius: 20px;">
                <h4>西门子</h4>
              </div>
              <div class="col-lg-12">
                <div class="line-dec"></div>
                <div class="row">
                  <div class="col-6">
                    <span>官网: <br> <strong>这里</strong></span>
                  </div>
                  <div class="col-6">
                    <span>重点 <br> <strong>特别</strong></span>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="main-button">
                  <a href="./Product">查看详情</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="item">
            <div class="row">
              <div class="col-lg-12">
                <img src="../assets/images/data/data4.jpeg" alt="" style="border-radius: 20px;">
                <h4>元工国际</h4>
              </div>
              <div class="col-lg-12">
                <div class="line-dec"></div>
                <div class="row">
                  <div class="col-6">
                    <span>官网: <br> <strong>这里</strong></span>
                  </div>
                  <div class="col-6">
                    <span>重点 <br> <strong>特别</strong></span>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="main-button">
                  <a href="./Product" >查看详情</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <!-- LAst section wroking -->
      <!-- <div class="intellifent_working">
    
        
      </div> -->

      <div class="create-nft">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading">
              <div class="line-dec"></div>
              <h2>生产及管理系统主要<em>特点</em></h2>
            </div>
          </div>
          <!-- <div class="col-lg-4">
            <div class="main-button">
              <a href="#">Create Your NFT Now</a>
            </div>
          </div> -->
          
          <div class="col-lg-4">
            <div class="item first-item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-02.png" alt="">
              </div>
              <h4>实时监控与数据分析</h4>
              <p>生产及管理系统通过实时监控生产过程，收集并分析大量数据，以帮助管理人员及时了解生产状态。
                实时监控有助于迅速识别潜在问题并采取相应措施，提高生产效率。</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item second-item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-04.png" alt="">
              </div>
              <h4>自动化与智能化</h4>
              <p>生产及管理系统借助先进的自动化技术，实现生产流程的自动化控制，减少人工干预。
                智能化功能通过算法和学习模型提高系统对生产环境的适应性，进一步提升生产效率和质量。</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-06.png" alt="">
              </div>
              <h4>资源优化与成本控制</h4>
              <p>系统能够优化资源分配，确保在生产过程中充分利用设备和人力资源，从而降低生产成本。
                通过实时监测和智能调度，生产及管理系统能够更有效地利用资源，提高生产线的整体效益。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p>Copyright © 2024 LangFang D&G Machinery Technology Co., Ltd.</p>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  // Import AOS styles (you can also include this in your styles if you prefer)
  import 'aos/dist/aos.css';

  // Import AOS
  import AOS from 'aos';
  // Import Swiper styles
  // import 'swiper/swiper-bundle.css';

  // // Import Swiper
  // import Swiper, { Navigation } from 'swiper';
  // Swiper.use([Navigation]);

  export default {
    name: 'Manage',
    data() {
        return {
          activeIndex: '1',
          activeIndex2: '1',
          isMobile: false,
          isOpen: false,
        }
      },
      
      methods: {
        handleSelect(key, keyPath) {
          console.log(key, keyPath);
          if (key === '1-1' ) {
          // Navigate to the target route using $router.push
          this.$router.push('/frist');
          // router.push({ path: '/intelligent', replace: true });
          }else if(key === '3'){
            this.$router.push('/Manage');
          }
          else if(key === '4'){
            this.$router.push('/Data');
          }else if(key === '5'){
          this.$router.push('/Electronic');
        }
        },
        initAOS() {
          AOS.init({
            duration: 1000, // Set the global duration for all animations
          });
        },
        checkIsMobile() {
          this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
        },
        redirectToMainPage() {
          // Navigate to the main page
          this.$router.push('/main');
        },
        handleDrawer() {
          const drawer= document.querySelector('.drawer');
          const popupmenu = document.querySelector('.el-popper'); // 使用querySelector选择.fixed-menu类的元素
          
          if (drawer) {
            popupmenu.classList.remove('drawer');
          }else{
            popupmenu.classList.add('drawer'); // 如果找到了.fixed-menu元素，添加drawer样式
          }
          },
          return_home(){
            this.$router.push('/');
          }
      },
      mounted() {
        this.checkIsMobile();
        window.addEventListener('resize', this.checkIsMobile);
        this.initAOS();
      },

    }
    window.addEventListener('scroll', function() {
    var fixedMenu = document.querySelector('.fixed-menu');
  
    // Adjust the scroll threshold based on your needs
    var scrollThreshold = 30;
  
    if (window.scrollY > scrollThreshold) {
      fixedMenu.classList.add('scrolling');
    } else {
      fixedMenu.classList.remove('scrolling');
    }
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
      /* Import external CSS files */
      @import '../assets/css/style.css';
      @import '../assets/css/fontawesome.css';
      @import '../assets/css/templatemo-liberty-market.css';
      /* @import '../assets/css/owl.css';
      @import '../assets/css/animate.css'; */
      /* @import '../vendor/bootstrap/css/bootstrap.min.css'; */
      
      .main-banner {
        height: 650px;
      }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  /* a {
    color: #42b983;
  } */
  .fixed-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  max-width: 100%;
  min-height: 70px;
  border-radius: 0px;
  width: 100%;
  top: 0;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  /* padding: 5px 10px 15px 10px; */
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  /* background: none; //覆盖背景 
  box-shadow: none; //覆盖阴影 
  backdrop-filter: none; */
  border-bottom: solid 0px var(--el-menu-border-color) !important;
  color: white;
  
}
.scrolling {
  top:30px;
   /* Updated top position when scrolling */
  max-width: 1200px;
  border-radius: 50px;
  justify-content: space-between;
  background-color: var(--el-menu-bg-color);
  color:black;
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
}
.centered-menu {
  display: flex;
  justify-content: center;
  
}
.menu-logo .logo img {
  width:auto;
  height:50px;
  /* padding-top: 10px; */
}
.el-popper {
    /* Add your styles for the el-popper class here */
    background-color: none !important; /* Example background color */
    border-radius: 5px; /* Example border radius */
    padding: 10px; /* Example padding */
  }
  .swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  /* .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  } */

  /* .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  } */
  .sensor-container .sensor-text p{
  justify-content: center;
  display: flex;
}
.sensor-container .sensor-text h2{
  justify-content: center;
  display: flex;
}
.sensor-diagram{
    display: flex;
    justify-content: center;
}
.sensor-diagram img{
  width:auto;
}
.el-menu--horizontal .el-menu .el-menu-item{
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: gray;
  border-radius: 50px;
}

.drawer-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
  </style>