<template>
    <!-- ***** Header Area Start ***** -->
    <div class="centered-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo fixed-menu"
        mode="horizontal"
        style="padding-left: 30px"
        @select="handleSelect"
      >
        <div class="menu-logo">
          <a class="logo">
              <img src="../assets/images/logo.png" alt="" class="logo_image" @click="return_home">
          </a>
        </div>
        <span style="font-size:30px;cursor:pointer;color:white; padding-right: 10px;" @click="handleDrawer" class="drawer_menu">&#9776;</span>
        <div class="menu-item">
          <el-sub-menu index="1">
            <template #title>智能化传感器</template>
            <el-menu-item index="1-1">液位传感器</el-menu-item>
            <el-menu-item index="1-2">温度传感器</el-menu-item>
          </el-sub-menu>
         
          <el-menu-item index="3" >生产及管理系统</el-menu-item>
          <el-menu-item index="4">数据采集与发布</el-menu-item>
          <el-menu-item index="5">设备电气控制改造</el-menu-item>
        </div>
      </el-menu>
    </div>
   
    <!-- ***** Header Area End ***** -->
  
    <!-- ***** Main Banner Area Start ***** -->
    <div class="main-banner">
      <div class="container">
        <div class="row">
            
        </div>
      </div>
    </div>
  <div class="author-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <div class="line-dec"></div>
            <h2>数据采集系统<em>主要设备</em>.</h2>
          </div>
        </div>
        <el-carousel :interval="4000" type="card" height="250px" >
            <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
                <div class="carousel-item-container">
                    <img :src="require(`../assets/images/machine/${item.photo}`)" alt="Carousel Item Photo" class="carousel-item-photo" />
                    <div class="rs232-name" v-show="showRS232Name === index">
                    {{ item.rs232Name }}
                    
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
      <!-- LAst section wroking -->
      <!-- <div class="intellifent_working">
    
        
      </div> -->

    <div class="create-nft">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading">
              <div class="line-dec"></div>
              <h2>数据采集与发布主要<em>特点</em></h2>
            </div>
          </div>
          <!-- <div class="col-lg-4">
            <div class="main-button">
              <a href="#">Create Your NFT Now</a>
            </div>
          </div> -->
          
          <div class="col-lg-4">
            <div class="item first-item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-02.png" alt="">
              </div>
              <h4>数据采集通用性较强</h4>
              <p>不仅可采集电气量，亦可采集非电气量。电气参数采集用交流离散采样，非电气参数采集采用继电器巡测，
                信号处理由高精度隔离运算放大器AD202JY调理，线性度好，精度高。。</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item second-item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-04.png" alt="">
              </div>
              <h4>模块化设计</h4>
              <p>系统采用分布式结构，以模块化设计为基础。数据采集利用光隔离的RS-485网络，确保高效通信和安全性。
                后台系统可灵活构建多种分布式网络，适应不同规模和需求。</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="item">
              <div class="icon">
                <img src="../assets/images/product_icons/icon-06.png" alt="">
              </div>
              <h4>数据处理</h4>
              <p>数据处理在WindowsNT平台上采用VisualC++语言编程，处理能力强、速度快、界面友好，可实现网络数据共享。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="intellifent_detail" style="padding: 60px 0px 150px 0px;">
        <!-- Section 1: Image on the left, Text on the right -->
        <div class="section-heading" >
            <div class="line-dec"></div>
            <h2>液位传感器的<em>类型</em></h2>
        </div>
        <div class="py-7 px-4" style="padding: 30px" data-aos="fade-up" data-aos-duration="2000">
          <!-- Added px-4 for horizontal padding -->
          <div class="container">
            <div class="row justify-content-center align-items-center gx-lg-0">
              <div
                class="col-md-6"
                style="display: flex; justify-content: flex-start"
              >
                <div
                  class="image-container"
                  style="max-height: 50vh; width: 100%"
                >
                  <img
                    class="images"
                    src="../assets/images/intelligent/product_1.jpg"
                    style="width: 100%; height: auto"
                  />
                </div>
              </div>

              <div class="col-md-6 p-4 p-mb-5" style="text-align: left" >
                <h2
                  class="mb-3"
                  style="font-weight: 600; font-size: 25px; line-height: 48px"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  浮球式液位传感器
                </h2>
                <p class="text-sm" id="block_explain">
                  <br />由行业产品磁性浮球、测量导管、信号单元、电子单元、接线盒及安装件组成。一般磁性浮球的比重小于0.5，可漂于液面之 上并沿测量导管上下移动。
                  导管内装有测量元件，它可以在外磁作用下将被测液位信号转换成正比于液位变化的电阻信号，并将电子单元转换成4～20ma或其它 电子商务标准信号输出。
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Section 2: Image on the right, Text on the left -->
        <div class="py-7 px-4" style="padding: 30px" data-aos="fade-up" data-aos-duration="2000">
          <!-- Added px-4 for horizontal padding -->
          <div class="container">
            <div class="row justify-content-center align-items-center gx-lg-0">
              <div
                class="col-md-6 order-md-2"
                style="display: flex; justify-content: flex-end"
              >
                <div
                  class="image-container"
                  style="max-height: 50vh; width: 100%"
                >
                  <img
                    class="images"
                    src="../assets/images/intelligent/product_2.jpg"
                    style="width: 100%; height: auto"
                  />
                </div>
              </div>
              <div class="col-md-6 p-4 p-mb-5" style="text-align: left">
                <h2
                  class="mb-3"
                  style="font-weight: 600; font-size: 25px; line-height: 48px"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  浮筒式液位传感器
                </h2>
                <p class="text-sm" id="block_explain">
                  <br />是将磁性浮球改为浮筒，浮筒式液位传感器是利用微小的金属膜应变传感技术来测量液体的液位、界位或密度的。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="py-7 px-4" style="padding: 30px" data-aos="fade-up" data-aos-duration="2000">
          <!-- Added px-4 for horizontal padding -->
          <div class="container">
            <div class="row justify-content-center align-items-center gx-lg-0">
              <div
                class="col-md-6"
                style="display: flex; justify-content: flex-start"
              >
                <div
                  class="image-container"
                  style="max-height: 50vh; width: 100%"
                >
                  <img
                    class="images"
                    src="../assets/images/intelligent/product_3.png"
                    style="width: 100%; height: auto"
                  />
                </div>
              </div>

              <div class="col-md-6 p-4 p-mb-5" style="text-align: left" >
                <h2
                  class="mb-3"
                  style="font-weight: 600; font-size: 25px; line-height: 48px"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                静压式液位传感器
                </h2>
                <p class="text-sm" id="block_explain">
                  <br />是利用防爆流量计液体静压力的测量原理工作。一般选用硅压力测压传感器将测量到的压力转换成电信号，
                  之后再经放大电路放大和补偿电路补偿，最后以4～20ma或0～10ma电流方式输出。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p>Copyright © 2024 LangFang D&G Machinery Technology Co., Ltd.</p>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  // Import AOS styles (you can also include this in your styles if you prefer)
  import 'aos/dist/aos.css';

  // Import AOS
  import AOS from 'aos';
  // Import Swiper styles
  // import 'swiper/swiper-bundle.css';

  // // Import Swiper
  // import Swiper, { Navigation } from 'swiper';
  // Swiper.use([Navigation]);

  export default {
    name: 'Data',
    data() {
        return {
          activeIndex: '1',
          activeIndex2: '1',
          carouselItems: [
                { photo: 'machine.jpeg', rs232Name: '配备RS232' },
                { photo: 'machine.jpeg', rs232Name: '配备USB接口' },
                { photo: 'machine.jpeg', rs232Name: '配备RJ45接口' },
                { photo: 'machine.jpeg', rs232Name: '配备VGA视频' },
                { photo: 'machine.jpeg', rs232Name: '内置WIFI模块' },
                { photo: 'machine.jpeg', rs232Name: '32G数据存储空间' },
                { photo: 'machine.jpeg', rs232Name: '4.3英寸触摸屏' },
                // Add more items as needed
            ],
            showRS232Name: null,
            visible: false,
            isMobile: false,
            isOpen: false
        }
      },
      methods: {
        handleSelect(key, keyPath) {
          console.log(key, keyPath);
          if (key === '1-1' ) {
          // Navigate to the target route using $router.push
          this.$router.push('/frist');
          // router.push({ path: '/intelligent', replace: true });
          }else if(key === '3'){
            this.$router.push('/Manage');
          }
          else if(key === '4'){
            this.$router.push('/Data');
          }else if(key === '5'){
          this.$router.push('/Electronic');
        }
        },
        initAOS() {
          
        },
        handleMouseEnter(index) {
            this.showRS232Name = index;
        },
        handleMouseLeave() {
            this.showRS232Name = null;
        },
        checkIsMobile() {
      this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
    },
    redirectToMainPage() {
      // Navigate to the main page
      this.$router.push('/main');
    },
    handleDrawer() {
      const drawer= document.querySelector('.drawer');
      const popupmenu = document.querySelector('.el-popper'); // 使用querySelector选择.fixed-menu类的元素
      
      if (drawer) {
        popupmenu.classList.remove('drawer');
      }else{
        popupmenu.classList.add('drawer'); // 如果找到了.fixed-menu元素，添加drawer样式
      }
      },
      return_home(){
         this.$router.push('/');
      },
      },
      mounted() {
        this.initAOS();
        this.checkIsMobile();
        window.addEventListener('resize', this.checkIsMobile);
      },

    }
    
    window.addEventListener('scroll', function() {
    var fixedMenu = document.querySelector('.fixed-menu');
  
    // Adjust the scroll threshold based on your needs
    var scrollThreshold = 30;
  
    if (window.scrollY > scrollThreshold) {
      fixedMenu.classList.add('scrolling');
    } else {
      fixedMenu.classList.remove('scrolling');
    }
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
      /* Import external CSS files */
      @import '../assets/css/style.css';
      @import '../assets/css/fontawesome.css';
      @import '../assets/css/templatemo-liberty-market.css';
      /* @import '../assets/css/owl.css';
      @import '../assets/css/animate.css'; */
      /* @import '../vendor/bootstrap/css/bootstrap.min.css'; */
      
      .main-banner {
        height: 650px;
      }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  /* a {
    color: #42b983;
  } */
  .fixed-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  max-width: 100%;
  min-height: 70px;
  border-radius: 0px;
  width: 100%;
  top: 0;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  /* padding: 5px 10px 15px 10px; */
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  /* background: none; //覆盖背景 
  box-shadow: none; //覆盖阴影 
  backdrop-filter: none; */
  border-bottom: solid 0px var(--el-menu-border-color) !important;
  color: white;
  
}
.scrolling {
  top:30px;
   /* Updated top position when scrolling */
  max-width: 1200px;
  border-radius: 50px;
  justify-content: space-between;
  background-color: var(--el-menu-bg-color);
  color:black;
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
}
.centered-menu {
  display: flex;
  justify-content: center;
  
}
.menu-logo .logo img {
  width:auto;
  height:50px;
  /* padding-top: 10px; */
}
.el-popper {
    /* Add your styles for the el-popper class here */
    background-color: none !important; /* Example background color */
    border-radius: 5px; /* Example border radius */
    padding: 10px; /* Example padding */
  }
  .swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.carousel-item-container {
  position: relative;
}

.carousel-item-photo {
  width: 100%; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  display: block;
}
.image-container {
    position: relative;
    overflow: hidden;
}
.rs232-name {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 12px;
  /* visibility: hidden;
    opacity: 0; */
    transition: visibility 0s, opacity 0.5s ease;
  height:100px;
  width:100px;
  color: black;
}

.carousel-item-container:hover .rs232-name {
  visibility: visible;
    opacity: 1;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

 
  /* .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  } */

  /* .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  } */
  .sensor-container .sensor-text p{
  justify-content: center;
  display: flex;
}
.sensor-container .sensor-text h2{
  justify-content: center;
  display: flex;
}
.sensor-diagram{
    display: flex;
    justify-content: center;
}
.sensor-diagram img{
  width:auto;
}
.el-menu--horizontal .el-menu .el-menu-item{
  background-color: transparent;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color:rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}
.drawer-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
  </style>